export default [
  {
    title: 'Ana Sayfa',
    route: 'Portal',
    icon: 'HomeIcon',
  },
  {
    title: 'Kurumsal',
    route: 'Company',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'Eğitimler',
    route: 'Education',
    icon: 'BookOpenIcon',
  },
  {
    title: 'Doküman Arşivi',
    route: 'Documents',
    icon: 'BookIcon',
  },
  {
    title: 'Öğrenilmiş Dersler',
    route: 'BookOpenIcon',
    icon: 'BookIcon',
  },
  {
    title: 'Fotoğraf Galerisi',
    route: 'Gallery',
    icon: 'CameraIcon',
  },
]
